<!-- 个人信息 -->
<template>
	<div class="myInfo-page">
		<div class="nav-title">个人信息</div>
		<div class="info-list">
			<!-- <div class="info">
				<div class="title">角色</div>
				<div class="content">{{ MyInfo.s_type | filterRole }}</div>
			</div> -->
			<div class="info">
				<div class="title">姓名</div>
				<div class="content">{{ MyInfo.s_name }}</div>
			</div>
			<!-- <div class="info">
				<div class="title">性别</div>
				<div class="content">{{ MyInfo.genderdata == "male" ? "男" : "女" }}</div>
			</div>
			<div class="info">
				<div class="title">所在区</div>
				<div class="content">{{ MyInfo.area || "未知" }}</div>
			</div> -->
			<div class="info">
				<div class="title">公司</div>
				<div class="content">{{ MyInfo.school || "未知" }}</div>
			</div>
			<div class="info" v-if="MyInfo.s_type == 0">
				<div class="title">年级</div>
				<div class="content">{{ MyInfo.grade || "未知" }}</div>
			</div>
			<div class="info" v-if="MyInfo.s_type == 0">
				<div class="title">班级</div>
				<div class="content">{{ MyInfo.theclass || "未知" }}</div>
			</div>
			<div class="info" v-if="MyInfo.s_type == 0">
				<div class="title">学号</div>
				<div class="content">{{ MyInfo.student_id || "未知" }}</div>
			</div>
			<div class="info" v-if="MyInfo.s_type == 1 && MyInfo.disciplines">
				<div class="title">学科</div>
				<div class="content">{{ MyInfo.disciplines.name || "未知" }}</div>
			</div>
			<div class="info" v-if="MyInfo.s_type == 1">
				<div class="title">邮箱</div>
				<div class="content">{{ MyInfo.s_email || "未知" }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { $getMyInfo } from "../../services/centerInfo";
export default {
	name: "MyInfo",
	data() {
		return {
			MyInfo: {},
		};
	},
	filters: {
		filterRole(val) {
			if (val == 0) {
				return "学生";
			} else if (val == 1) {
				return "教师";
			} else if (val == 1) {
				return "公开课注册用户";
			}
			return "其他";
		},
	},
	components: {},

	computed: {},

	created() {
		this.getMyInfo();
	},

	methods: {
		async getMyInfo() {
			const { data } = await $getMyInfo();
			this.MyInfo = data;
			console.log(data);
		},
	},
};
</script>

<style scoped lang="less">
.nav-title {
	height: 100px;
	padding-top: 35px;
	line-height: 65px;
	border-bottom: 1px solid #eeefef;
	font-size: 18px;
	font-weight: bold;
	color: #1c1f21;
}
.info-list {
	padding-top: 20px;
	.info {
		display: flex;
		align-items: center;
		margin-bottom: 16px;

		.title {
			width: 128px;
			flex: none;
			height: 62px;
			line-height: 62px;
			font-size: 16px;
			text-align: center;
			font-weight: bold;
			color: #1c1f21;
			background: #f3f5f7;
			border-radius: 4px;
			margin-right: 18px;
		}
		.content {
			width: 100%;
			border-bottom: 1px solid #eeefef;
			font-size: 16px;
			line-height: 62px;
			font-weight: 400;
			color: #1c1f21;
			padding-left: 24px;
			span {
				color: rgba(84, 92, 99, 0.5);
				margin: 0 15px;
			}
		}
	}
}
</style>
